import {
  actions,
  TransactionsFilter,
  LocationsFilter,
  CardsFilter,
} from './filters-reducer';
import { Thunk } from '../types';

export const filterCards =
  ({ id, dateRange }: CardsFilter): Thunk =>
  dispatch => {
    dispatch(actions.filterCards({ id, dateRange }));
  };

export const filterLocations =
  ({ id, brandId, postcode, status, countryCode }: LocationsFilter): Thunk =>
  dispatch => {
    dispatch(
      actions.filterLocations({ id, brandId, postcode, status, countryCode }),
    );
  };

export const filterTransactions =
  ({
    id,
    brandId,
    dateRange,
    transactionStatus,
    schemes,
    amount,
    qualifiedTransactions,
  }: TransactionsFilter): Thunk =>
  dispatch => {
    dispatch(
      actions.filterTransactions({
        id,
        brandId,
        dateRange,
        transactionStatus,
        schemes,
        amount,
        qualifiedTransactions,
      }),
    );
  };

export const { clearCards, clearLocations, clearTransactions, clear } = actions;
