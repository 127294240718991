/* eslint-disable no-await-in-loop */

import moment from 'moment';

import { getAllItems, getCount } from '../utils/pagination';
import { api, namespaces } from '../../config';
import http from '../../services/http-service';
import { TransactionOptions } from './transactions-model';
import { TransactionsFilter } from '../filters/filters-reducer';
import { Transaction } from '..';
import { filterGetByIdCall } from '../utils/transform';

class TransactionApiService {
  public clearTransaction(transactionId: string) {
    return http.patch(`transactions/${transactionId}`, { cleared: true });
  }

  public async getTransactions(
    { offerId, programId, last }: TransactionOptions,
    { id, brandId, dateRange }: TransactionsFilter,
  ) {
    const headers: any = {};
    let params: any = {};
    const user = localStorage.getItem(namespaces.USER);

    const { from, to } = dateRange || {};

    if (id) {
      return filterGetByIdCall(id, programId, this.getTransaction, [
        (transaction: Transaction) =>
          from && to
            ? moment(transaction.created).isSameOrAfter(from) &&
              moment(transaction.created).isSameOrBefore(to)
            : true,
        (transaction: Transaction) =>
          offerId ? transaction.offer?.id === offerId : true,
        (transaction: Transaction) =>
          brandId ? transaction.brand?.id === brandId : true,
      ]);
    }

    if (offerId && user) headers['fidel-user'] = user;

    if (!!from && !!to) params = { ...params, from, to };

    if (last) params = { ...params, start: JSON.stringify(last) };

    let url: string;
    if (offerId) url = `offers/${offerId}/transactions`;
    else if (!brandId) url = `programs/${programId}/transactions`;
    else url = `brands/${brandId}/programs/${programId}/transactions`;

    return http.get(url, { headers, params });
  }

  public async getAllTransactions(
    options: TransactionOptions,
    filters: TransactionsFilter,
  ) {
    return getAllItems(last =>
      this.getTransactions(
        {
          ...options,
          last,
        },
        filters,
      ),
    );
  }

  private getTransaction(transactionId: string) {
    return http.get(`transactions/${transactionId}`);
  }

  public async getTransactionsCount(
    options: TransactionOptions,
    filters: TransactionsFilter,
  ) {
    return getCount(last => {
      const { programId } = options;
      const { brandId, dateRange } = filters;
      const { from, to } = dateRange || {};

      const url = !brandId
        ? `programs/${programId}/transactions`
        : `brands/${brandId}/programs/${programId}/transactions`;

      const params: any = { select: 'count' };

      if (last) params.start = JSON.stringify(last);

      if (from && to) {
        params.from = from;
        params.to = to;
      }

      return http.get(url, { params });
    }, api.MAX_TRANSACTION_COUNT);
  }
}

export default TransactionApiService;
