import React, { useState, useEffect, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
} from 'antd';

import {
  AmountOperator,
  DateRange,
  CardsFilter,
  TransactionsFilter,
  LocationsFilter,
} from '../../store/filters/filters-reducer';
import { SchemeStatus } from '../../store/locations/locations-model';
import { TransactionStatus } from '../../store/transactions/transactions-model';
import CardSchemeStatusTag from '../../modules/locations/components/StatusTag';
import DropdownBox, { DropdownText } from '../dropdown-box';
import { StatusTag } from '../../modules/transactions/components/TransactionTag';
import { Select } from '../../components/select';
import BrandsFilter from '../../components/brands-filter';
import Form from '../../components/form';
import { Caron } from '../../components/icons';
import {
  CheckboxContainer,
  RightArrow,
  NumberOfActiveFilters,
} from '../styled/filter-box';
import { GreyDropdownButton, SecondaryLinkButton } from '../buttons';
import { schemeOptions } from '../../utils/schemes';
import regex from '../../utils/regex';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const statusFilters: SchemeStatus[] = ['inactive', 'active', 'sync', 'failed'];

const transactionStatusFilters: TransactionStatus[] = [
  'cleared',
  'auth',
  'refund',
];

const { RangePicker } = DatePicker;

type AllFilters = TransactionsFilter & LocationsFilter & CardsFilter;

type AllFiltersKeys = (keyof AllFilters)[];

interface FilterBoxProps {
  filterName: 'cards' | 'locations' | 'transactions';
  filtersToHide?: string[];
  action: any;
  clear: any;
}

const FilterBox = ({
  filterName,
  filtersToHide = [],
  action,
  clear,
}: FilterBoxProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.filters);

  const selectedFilters: Partial<AllFilters> = filters[filterName];
  const filtersToShow: AllFiltersKeys = (
    Object.keys(selectedFilters) as AllFiltersKeys
  )
    .filter(
      activeFilter =>
        !filtersToHide.some(filterToHide => filterToHide === activeFilter),
    )
    .reduce((total, filter) => [...total, filter], [] as AllFiltersKeys);
  const numberOfActiveFilters = filters.activeFilters[filterName];

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  function getActiveFilters(reset?: boolean) {
    return Object.entries(selectedFilters).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: reset ? false : !!value }),
      {} as { [index: string]: boolean },
    );
  }

  const [activeFilters, setActiveFilters] = useState(getActiveFilters());

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<AllFilters>({
    mode: 'onBlur',
    shouldUnregister: true,
  });

  useEffect(
    () => () => {
      dispatch(clear());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function filterContent() {
    function transformDateRange(values: (Moment | null)[] | null = []) {
      if (values?.length === 0) return {};

      const [start, end] = values || [];
      const newDateRange: DateRange = {};
      if (start && moment(start).isValid())
        newDateRange.from = moment(start).startOf('day').format();
      if (end && moment(end).isValid())
        newDateRange.to = moment(end).endOf('day').format();

      return {
        dateRange: {
          ...newDateRange,
        },
      };
    }

    function transformAmount(amountNumber: number, amountOperator: string) {
      if (isNaN(amountNumber) || !amountOperator) return undefined;

      return {
        amount: {
          operator: amountOperator,
          number: amountNumber,
        },
      };
    }

    async function applyFilters(event: any) {
      const { amountNumber, amountOperator } = event;

      delete event.amountNumber;
      delete event.amountOperator;

      dispatch(
        action({
          ...event,
          ...transformAmount(amountNumber, amountOperator),
          ...transformDateRange(event.dateRange),
        }),
      );

      setShowDropdownMenu(false);
    }

    const operators = [
      { label: t('filters.amount.less'), value: AmountOperator.LESS },
      { label: t('filters.amount.equal'), value: AmountOperator.EQUAL },
      { label: t('filters.amount.greater'), value: AmountOperator.GREATER },
    ];

    return (
      <DropdownBox>
        <DropdownText>{t('filters.title')}</DropdownText>
        <Form>
          {filtersToShow.includes('id') && (
            <CheckboxFilter
              text={t('filters.id.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              name="id"
              testid="id-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'id',
                  render: ({ field }) => (
                    <Input
                      {...field}
                      allowClear
                      defaultValue={selectedFilters.id}
                      placeholder={t('filters.id.placeholder')}
                    />
                  ),
                  control,
                  rules: {
                    required: true,
                    validate: {
                      exactId: value => regex.uuid.test(value),
                    },
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('brandId') && (
            <CheckboxFilter
              text={t('filters.brand.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              name="brandId"
              testid="brand-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'brandId',
                  render: ({ field: { ref, ...rest } }) => (
                    <BrandsFilter
                      {...rest}
                      defaultValue={selectedFilters.brandId}
                    />
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('dateRange') && (
            <CheckboxFilter
              name="dateRange"
              text={t('filterBox.label.date')}
              show={activeFilters}
              setShow={setActiveFilters}
              testid="date-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'dateRange',
                  render: ({ field: { ref, ...rest } }) => (
                    <RangePicker
                      {...rest}
                      defaultValue={[
                        moment(selectedFilters.dateRange?.from),
                        moment(selectedFilters.dateRange?.to),
                      ]}
                      placeholder={[
                        t('datepicker.startDate'),
                        t('datepicker.endDate'),
                      ]}
                      separator={<RightArrow />}
                    />
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('postcode') && (
            <CheckboxFilter
              name="postcode"
              text={t('filters.postcode.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              testid="postcode-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'postcode',
                  render: ({ field }) => (
                    <Input
                      {...field}
                      defaultValue={selectedFilters.postcode}
                      allowClear
                      placeholder={t('filters.postcode.placeholder')}
                      data-testid="postcode-filter"
                    />
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('status') && (
            <CheckboxFilter
              name="status"
              text={t('filters.status.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              testid="status-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'status',
                  render: ({ field }) => (
                    <Select
                      {...field}
                      data-name="filter-status"
                      data-testid="filter-status"
                      placeholder={t('filters.status.placeholder')}
                      defaultValue={selectedFilters.status}
                      dropdownMatchSelectWidth={false}
                    >
                      {statusFilters.map(statusFilter => (
                        <Select.Option
                          key={statusFilter}
                          value={
                            statusFilter === 'sync' ? 'syncing' : statusFilter
                          }
                        >
                          <CardSchemeStatusTag status={statusFilter} />
                        </Select.Option>
                      ))}
                    </Select>
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('transactionStatus') && (
            <CheckboxFilter
              name="transactionStatus"
              text={t('filters.status.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              testid="transactionStatus-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'transactionStatus',
                  render: ({ field }) => (
                    <Select
                      {...field}
                      data-testid="filter-transaction-status"
                      placeholder={t('filters.status.placeholder')}
                      defaultValue={selectedFilters.transactionStatus}
                      dropdownMatchSelectWidth={false}
                    >
                      {transactionStatusFilters.map(transactionStatusFilter => (
                        <Select.Option
                          key={transactionStatusFilter}
                          value={transactionStatusFilter}
                        >
                          <StatusTag status={transactionStatusFilter} />
                        </Select.Option>
                      ))}
                    </Select>
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('schemes') && (
            <CheckboxFilter
              name="schemes"
              text={t('filters.schemes.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              testid="schemes-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'schemes',
                  render: ({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      data-testid="filter-schemes"
                      placeholder={t('filters.schemes.placeholder')}
                      defaultValue={selectedFilters.schemes}
                      dropdownMatchSelectWidth={false}
                    >
                      {schemeOptions.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('amount') && (
            <CheckboxFilter
              name="amountOperator"
              text={t('filters.amount.checkbox')}
              show={activeFilters}
              setShow={setActiveFilters}
              testid="amount-checkbox"
            >
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'amountOperator',
                  render: ({ field }) => (
                    <Select
                      {...field}
                      data-testid="filter-amount-operator"
                      placeholder={t('filters.amount.selectOperator')}
                      defaultValue={selectedFilters.amount?.operator}
                      dropdownMatchSelectWidth={false}
                    >
                      {operators.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
              <Form.ItemController
                errors={errors}
                controller={{
                  name: 'amountNumber',
                  render: ({ field }) => (
                    <InputNumber
                      {...field}
                      data-testid="filter-amount"
                      placeholder={t('filters.amount.enterValue')}
                      defaultValue={undefined}
                      value={selectedFilters?.amount?.number}
                    />
                  ),
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </CheckboxFilter>
          )}
          {filtersToShow.includes('qualifiedTransactions') && (
            <CheckboxContainer>
              <Form.ItemController
                style={{ padding: 0 }}
                controller={{
                  name: 'qualifiedTransactions',
                  render: ({ field: { onChange, ...rest } }) => (
                    <Checkbox
                      {...rest}
                      data-testid="filter-qualified-transactions"
                      defaultChecked={selectedFilters.qualifiedTransactions}
                      onChange={({ target }) => {
                        const { checked } = target;

                        onChange(checked);
                        setActiveFilters({
                          qualifiedTransactions: checked,
                        });
                      }}
                      checked={activeFilters.qualifiedTransactions}
                    >
                      {t('filters.qualifiedTransactions.checkbox')}
                    </Checkbox>
                  ),
                  control,
                }}
              />
            </CheckboxContainer>
          )}
          <Button
            type="primary"
            size="small"
            onClick={handleSubmit(applyFilters)}
          >
            {t('apply')}
          </Button>
        </Form>
      </DropdownBox>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        overlay={filterContent()}
        trigger={['click']}
        onVisibleChange={setShowDropdownMenu}
        visible={showDropdownMenu}
      >
        <GreyDropdownButton
          $disableAnimation
          $controlledVisibility={showDropdownMenu}
        >
          {t('filterBox.title')}{' '}
          <NumberOfActiveFilters number={numberOfActiveFilters} />
          <Caron />
        </GreyDropdownButton>
      </Dropdown>
      {numberOfActiveFilters > 0 && (
        <SecondaryLinkButton
          size="small"
          onClick={() => {
            dispatch(clear());
            setActiveFilters(getActiveFilters(true));
          }}
        >
          {t('filterBox.clearFilters')}
        </SecondaryLinkButton>
      )}
    </div>
  );
};

interface CheckboxFilterProps {
  text: string;
  show: { [index: string]: boolean };
  setShow: (value: { [index: string]: boolean }) => void;
  testid?: string;
  name: string;
  children?: ReactNode;
}

const CheckboxFilter = ({
  text,
  children,
  testid,
  name,
  show,
  setShow,
}: CheckboxFilterProps) => (
  <CheckboxContainer padding>
    <Checkbox
      data-testid={testid}
      checked={show[name]}
      onChange={e => {
        setShow({ ...show, [name]: e.target.checked });
      }}
    >
      {text}
    </Checkbox>
    {show[name] && <div>{children}</div>}
  </CheckboxContainer>
);

export default FilterBox;
