import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DateRange {
  to?: string;
  from?: string;
}

export enum AmountOperator {
  EQUAL = '==',
  GREATER = '>',
  LESS = '<',
}

export interface TransactionsFilter {
  id?: string;
  brandId?: string;
  dateRange?: DateRange;
  transactionStatus?: string;
  schemes?: string[];
  amount?: { operator?: AmountOperator; number: number };
  qualifiedTransactions?: boolean;
}

export interface LocationsFilter {
  id?: string;
  status?: string;
  postcode?: string;
  brandId?: string;
  select?: string;
  countryCode?: string;
}

export interface UniqueLocationsFilters {
  address?: string;
  city?: string;
  countryCode?: string;
  postcode?: string;
  stateCode?: string;
}

export interface CardsFilter {
  id?: string;
  dateRange?: DateRange;
}

export interface FiltersState {
  activeFilters: {
    cards: number;
    locations: number;
    transactions: number;
  };
  cards: CardsFilter;
  locations: LocationsFilter;
  transactions: TransactionsFilter;
}

const initialState: FiltersState = {
  activeFilters: {
    cards: 0,
    locations: 0,
    transactions: 0,
  },
  cards: {
    id: undefined,
    dateRange: undefined,
  },
  locations: {
    id: undefined,
    brandId: undefined,
    postcode: undefined,
    status: undefined,
  },
  transactions: {
    id: undefined,
    brandId: undefined,
    dateRange: undefined,
    transactionStatus: undefined,
    schemes: undefined,
    amount: undefined,
    qualifiedTransactions: undefined,
  },
};

const getActiveFilters = (payload: PayloadAction<any>): number =>
  Object.values(payload).reduce(
    (total, filter) => (filter ? total + 1 : total),
    0,
  );

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    clear(state) {
      state.cards = initialState.cards;
      state.locations = initialState.locations;
      state.transactions = initialState.transactions;
      state.activeFilters.cards = 0;
      state.activeFilters.locations = 0;
      state.activeFilters.transactions = 0;
    },
    clearCards(state) {
      state.cards = initialState.cards;
      state.activeFilters.cards = 0;
    },
    filterCards(state, action: PayloadAction<any>) {
      state.cards = action.payload;
      state.activeFilters.cards = getActiveFilters(action.payload);
    },
    clearLocations(state) {
      state.locations = initialState.locations;
      state.activeFilters.locations = 0;
    },
    filterLocations(state, action: PayloadAction<any>) {
      state.locations = action.payload;
      state.activeFilters.locations = getActiveFilters(action.payload);
    },
    clearTransactions(state) {
      state.transactions = initialState.transactions;
      state.activeFilters.transactions = 0;
    },
    filterTransactions(state, action: PayloadAction<any>) {
      state.transactions = action.payload;
      state.activeFilters.transactions = getActiveFilters(action.payload);
    },
  },
});

export default filterSlice.reducer;
export const { actions } = filterSlice;
